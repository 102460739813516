<template>
  <div>
    <!-- 列表-开始 -->
      <el-table
        class="index-list-table"
        :data="tableData"
        height="calc(100vh - 190px)">
        <el-table-column prop="employeeName" label="考试人员">
          <template slot-scope="{row}">
            {{row.employeeName||'—'}}
          </template>
        </el-table-column>
        <el-table-column prop="examTime" label="考试时间">
          <template slot-scope="{row}">
            {{row.examTime||'—'}}
          </template>
        </el-table-column>
        <el-table-column prop="examInfoResult" label="考试结果">
          <template slot-scope="{row}">
            {{row.examInfoResult=='1'?'通过':row.examInfoResult=='0'?'未通过':'—'}}
          </template>
        </el-table-column>
        <el-table-column prop="mark" label="考试成绩">
          <template slot-scope="{row}">
            {{row.mark||'—'}}
          </template>
        </el-table-column>
        <el-table-column prop="examName" label="试卷名称">
          <template slot-scope="{row}">
            {{row.examName||'—'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="144px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" class="text-btn mr24" @click="_examinationDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页--开始 -->
      <el-pagination
        class="fx-row-end pt16"
        @size-change="_handleSizeChange"
        @current-change="_handleCurrentChange"
        :current-page="searchForm.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="searchForm.total"
      >
      </el-pagination>
      <!-- 分页--结束 -->
      <!-- 列表-结束 -->
  </div>
</template>
<script>
import { getNotificationApi } from '@/api/api'
export default {
  data () {
    return {
      tableData: [],
      searchForm: {
        page: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  created () {
    this.getNotification()
  },
  methods: {
    getNotification () {
      getNotificationApi({ ...this.searchForm, examInfoId: this.$route.query.id }).then(res => {
        if (res.code === 200) {
          if (res.object.results && res.object.results.length > 1) {
            this.tableData = res.object.results
            this.searchForm.total = res.object.total
          } else {
            this.$router.push({
              path: 'studentExamResultDetail',
              query: {
                examInfoId: res.object.results[0].examInfoId,
                employeeId: res.object.results[0].employeeId
              }
            })
          }
        }
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.getNotification()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.getNotification()
    },
    _examinationDetail (row) {
      // 考试详情
      this.$router.push({
        path: 'studentExamResultDetail',
        query: {
          examInfoId: row.examInfoId,
          employeeId: row.employeeId
        }
      })
    }
  }
}
</script>

<style></style>
